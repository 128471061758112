// import modules
import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

// import components
import MediaIcon from "../components/media-icon/media-icon.component"
import Layout from "../components/layout"
import ProjectPreview from "../components/project-preview/project-preview.component"
import BlogPreview from "../components/blog-preview/blog-preview.component"
import MediumBlogPreview from "../components/blog-preview/blog-preview-medium.component"
import ContactForm from "../components/contact-form/contact-form.component"

// import styles
import styles from "./index.module.scss"
import "../utils/fontawesome"

// import data
import contactInfo from "../../site/settings/contact_info.json"
import TypingComponent from "../components/typing-component/typing.component"
import { faMedium } from "@fortawesome/free-brands-svg-icons"

export default () => {
  return (
    <Layout>
      <SEO title="Home Page" description="Welcome to VietJs website" />
      <main id={styles.home}>
        {/* Headline */}
        <div className={styles.headline}>
          <div className={styles.headings}>
            <h1 className={styles["lg-heading"]}>Viet Nguyen</h1>
            <div className={styles["sm-heading"]}>
              <TypingComponent />
            </div>
          </div>
          <div className={styles.icons}>
            <MediaIcon
              linkUrl={contactInfo.github}
              name="github"
              isBrand={true}
            />
            <MediaIcon
              linkUrl={contactInfo.linkedin}
              name="linkedin"
              isBrand={true}
            />
            <MediaIcon
              linkUrl={contactInfo.twitter}
              name="twitter"
              isBrand={true}
            />

            <MediaIcon
              linkUrl={contactInfo.medium}
              name="medium"
              icon={faMedium}
              isBrand={true}
            />
          </div>
          {/* <div className={styles["resume-downloader"]}>
            Get My Resume:
            <MediaIcon
              linkUrl={contactInfo.resume}
              name="file-contract"
              isBrand={false}
            />
          </div> */}
        </div>
        <div className="container">
          {/* About me */}
          <div className={`${styles.about} ${styles.section}`}>
            <h3 className={styles.title}>Little Things About Me...</h3>
            <p>
              I am a software developer living in Toronto, Canada. My passion is
              creating clean and elegant software applications. I have spent a
              lot of my time experimenting with the MERN stack (React, Node.js,
              MongoDB, Express) and .NET platform. If you want to know more
              about me, please click <Link to="/about">here</Link>.
            </p>
          </div>

          {/* Projects */}
          <div id="projects" className={`${styles.projects} ${styles.section}`}>
            <h3 className={styles.title}>Some Of My Projects...</h3>
            <ProjectPreview limit={3} />
            <div className={styles["button-container"]}>
              <Link to="/projects" className={styles.button}>
                View more
              </Link>
            </div>
          </div>

          {/* Blogs */}
          <div className={`${styles.blogs} ${styles.section}`}>
            <h3 className={styles.title}>Recent Blog Posts</h3>
            {/* <BlogPreview limit={2} /> */}
            <MediumBlogPreview limit={3} />

            <div className={styles["button-container"]}>
              <Link to="/blogs" className={styles.button}>
                View more
              </Link>
            </div>
          </div>

          {/* Contact */}
          <div className={`${styles.contact} ${styles.section}`}>
            <h3>Contact me</h3>
            <ContactForm />
          </div>
        </div>
      </main>
    </Layout>
  )
}
