import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faGithub,
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

import { faEye, faFileContract } from "@fortawesome/free-solid-svg-icons"
library.add(faGithub, faFacebook, faTwitter, faLinkedin, faEye, faFileContract)
