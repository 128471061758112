import React from "react"
import Typing from "react-typing-animation"

const TypingComponent = () => {
  const messages = ["Software Developer", "Programmer", "Web Developer"]
  return (
    <Typing speed={50} loop={true}>
      <span>I am a </span>
      {messages.map((message, index) => {
        return (
          <span key={index}>
            <span>{message}</span>
            <Typing.Delay ms={1000} />
            <Typing.Backspace count={message.length} />
          </span>
        )
      })}
      <Typing.Backspace count={10} />
      <Typing.Reset count={0} delay={500} />
    </Typing>
  )
}

export default TypingComponent
